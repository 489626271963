<template>
  <div class="pasenger_form">
    <b-form ref="form" class="d-flex justify-content-between" @submit.stop.prevent="() => {}">
      <h4 class="colum-title">{{ `${$t("product-page.customer-details")} #${index+1}` }}:</h4>
      <b-form-group :label='device==="desktop" ? $t("booking.first-name") : ""' label-for="input-2">
        <b-form-input
          v-model="firstName"
          :placeholder="$t('booking.first-name')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="firstNameState"
          aria-describedby="input-firstname-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-firstname-feedback">
          {{$t("booking.only-eng-character-message")}}
          <!-- {{(supplier.toLowerCase() === 'ISRO'.toLowerCase()) ? $t("booking.only-eng-character-message") : ''}} -->
          <!-- {{$t("booking.no-empty")}} -->
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label='device ==="desktop" ? $t("booking.last-name") : ""' label-for="input-2">
        <b-form-input
          v-model="lastName"
          :placeholder="$t('booking.last-name')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="lastNameState"
          aria-describedby="input-lastname-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-lastname-feedback">
          {{$t("booking.only-eng-character-message")}}
          <!-- {{(supplier.toLowerCase() === 'ISRO'.toLowerCase()) ? $t("booking.only-eng-character-message") : ''}} -->
          <!-- {{$t("booking.no-empty")}} -->
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="device == 'desktop' ? $t('booking.gender') : ''"
        label-for="input-3"
      >
        <b-form-select
          :aria-invalid="true"
          v-model="paxType"
          :options="paxTypes"
          required
          :state="paxState"
          :disabled="readOnly"
          @change="updateUserInfo"
          :class="!paxState ? 'danger' : ''"
          aria-describedby='select-pax-feedback'
        ></b-form-select>
      </b-form-group>

      <b-form-group>
        <label v-if="device == 'desktop'">{{ $t('booking.date-birth') }}</label>
        <date-picker
          required
          :state='birthState'
          ref="datepicker"
          v-model="birthDate"
          :config="limitDate"
          :placeholder="$t('booking.date-birth')"
          @dp-change="updateUserInfo"
          :class="[(!birthState ? 'invalid' : 'valid'), 'text-left']"
        />
        <!-- <div class="icon-form" v-if="birthState"><i class="fas fa-check"></i></div> -->
      </b-form-group>

      <b-form-group
        :label="device == 'desktop' ? $t('booking.nationality') : ''"
        label-for="input-3"
      >
        <b-form-select
          :aria-invalid="true"
          v-model="nationality"
          :options="nationalityOptions"
          required
          :state="paxState"
          :disabled="readOnly"
          @change="updateUserInfo"
          :class="!paxState ? 'danger' : ''"
          aria-describedby='select-pax-feedback'
        ></b-form-select>
      </b-form-group>

      <b-form-group :label='device ==="desktop" ? $t("booking.phone") : ""' label-for="input-2">
        <b-form-input
          v-model="phone"
          :placeholder="$t('booking.phone')"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="phoneState"
          aria-describedby="input-lastname-feedback"
        ></b-form-input>
      </b-form-group>

      <b-form-group :label='device ==="desktop" ? $t("booking.email") : ""' label-for="input-1">
        <b-form-input
          v-model="email"
          type="email"
          placeholder="email@example.com"
          required
          :readonly="readOnly"
          @change="updateUserInfo"
          :state="emailState"
          aria-describedby="input-lastname-feedback"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" class="d-none">{{ $t("booking.submit") }}</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BFormSelect } from 'bootstrap-vue';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const dayFormatShow = 'DD-MM-YYYY', dayFormatISO = 'YYYY-MM-DD';
const maxMan = 120, maxChild = 12, maxInfant = 2;

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    supplier: {
      type: String,
      default: '',
    },
    originDate: {
      type: String,
      default: dayjs(),
    },
  },
  data() {
    return {
      paxTypes: [
        { value: 'M', text: this.$t('booking.mr') },
        { value: 'F', text: this.$t('booking.miss') },
        { value: 'C', text: this.$t('booking.children') },
        { value: 'I', text: this.$t('booking.infant') },
      ],
      firstName: '',
      lastName: '',
      paxType: 'M',
      email: '',
      phone: '',
      config: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
        viewMode: 'years',
      },
      birthDate: '01-01-1980', // dayjs().subtract(25, 'year').toString(),
      nationality: 'RU',
    };
  },
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormSelect,
    datePicker,
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      devMode: 'GET_MODE',
      nationalityList: 'GET_NATIONALITY',
    }),
    fNameState() {
      return !!this.firstName;
    },
    lNameState() {
      return !!this.lastName;
    },
    emailState() {
      return !!this.email && !!this.validateEmail(this.email);
    },
    phoneState() {
      return !!this.phone;
    },
    firstNameState() {
      return /[^A-Za-z]/ig.test(this.firstName) && this.firstName.trim().length > 2;
    },
    lastNameState() {
      return /[^A-Za-z]/ig.test(this.lastName) && this.lastName.trim().length > 2;
    },
    paxState() {
      return !!this.paxType && this.paxType !== '';
    },
    birthState() {
      const depDate = dayjs(this.originDate, dayFormatISO);
      let birth = dayjs(this.birthDate, dayFormatShow);
      const retryState = JSON.parse(window.localStorage.getItem('retryState'));
      if (retryState && retryState.readOnly) {
        birth = dayjs(this.birthDate, dayFormatISO);
      }
      let boolAvailBirth = !!this.birthDate;
      if (boolAvailBirth) {
        switch (this.paxType) {
          case 'M':
            boolAvailBirth = birth.isBefore(depDate.subtract(maxChild, 'year')) && birth.isAfter(depDate.subtract(maxMan, 'year'));
            break;
          case 'C':
            boolAvailBirth = birth.isBefore(depDate.subtract(maxInfant, 'year')) && birth.isAfter(depDate.subtract(maxChild, 'year'));
            break;
          case 'I':
            boolAvailBirth = birth.isBefore(depDate) && birth.isAfter(depDate.subtract(maxInfant, 'year'));
            break;
          default:
        }
      }
      return boolAvailBirth;
    },
    limitDate() {
      let minDate = dayjs(this.originDate, dayFormatISO),
        maxDate = dayjs(this.originDate, dayFormatISO);
      let initDate = '1980-01-01';

      switch (this.user.generation) {
        case 'adult':
          maxDate = maxDate.subtract(maxMan, 'year');
          minDate = minDate.subtract(maxChild, 'year');
          break;
        case 'child':
          maxDate = maxDate.subtract(maxChild, 'year');
          minDate = minDate.subtract(maxInfant, 'year');
          initDate = maxDate.add(1, 'day').format(dayFormatISO);
          break;
        case 'infant':
          maxDate = maxDate.subtract(maxInfant, 'year');
          initDate = null;
          break;
        default:
      }
      return {
        format: dayFormatShow,
        useCurrent: false,
        viewMode: 'years',
        minDate: Date.parse(maxDate.format(dayFormatISO).toString()),
        maxDate: Date.parse(minDate.format(dayFormatISO).toString()),
        defaultDate: initDate,
      };
    },
    nationalityOptions() {
      const { nationalityList } = this;
      if (!nationalityList || nationalityList.length === 0) return [];
      return nationalityList.map((nation) => ({ value: nation.code, text: nation.name }));
    },
  },
  watch: {
    // readOnly() {
    //   if (this.readOnly) {
    //     this.$refs.datepicker.dp.disable();
    //   } else {
    //     this.$refs.datepicker.dp.enable();
    //   }
    // },
    // lang: 'updateLableWithLang',
    user: {
      handler() {
        this.setUserInformation();
        this.updateUserInfo();
      },
      deep: true,
    },
  },
  methods: {
    updateUserInfo() {
      if (this.supplier.toLowerCase() === 'ISRO'.toLowerCase()) {
        this.firstName = this.firstName.replace(/[^A-Za-z]/ig, '');
        this.lastName = this.lastName.replace(/[^A-Za-z]/ig, '');
      }
      this.firstName = this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1);
      this.lastName = this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1);
      const userInfo = {
        validate: false,
        groupID: this.user.groupID,
        id: this.user.id,
        firstName: this.firstName,
        lastName: this.lastName,
        paxType: this.paxType,
        email: this.email,
        phone: this.phone,
        birthDate: this.birthDate,
        nationality: this.nationality,
      };
      userInfo.validate = (this.fNameState && this.lNameState && this.paxState && this.birthState && ((this.user.generation === 'adult' && this.emailState && this.phoneState) || this.user.generation !== 'adult'));
      this.$emit('updateUser', userInfo);
    },
    setUserInformation() {
      this.email = this.user.email;
      this.phone = this.user.phone;
    },
    limitPaxtypes() {
      switch (this.user.generation) {
        case 'adult':
          this.paxTypes[2].disabled = true;
          this.paxTypes[3].disabled = true;
          this.paxType = 'M';
          break;
        case 'child':
          this.paxTypes.forEach((el) => {
            el.disabled = true;
          });
          this.paxType = 'C';
          break;
        case 'infant':
          this.paxTypes.forEach((el) => {
            el.disabled = true;
          });
          this.paxType = 'I';
          break;
        default:
      }
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
  created() {
    this.limitPaxtypes();
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.email = this.user.email;
    this.phone = this.user.phone;
    if (this.devMode) {
      if (this.firstName === '') this.firstName = 'test';
      if (this.lastName === '') this.lastName = 'test';
      if (this.email === '') this.email = 'ofirfishler@gmail.com';
      if (this.phone === '') this.phone = '543320148';
      this.updateUserInfo();
    }
  },
  mounted() {
  },
};
</script>

<style>
  .invalid-feedback {
    line-height: 0.9rem;
  }
  .form-group label {
    white-space: nowrap;
  }
  .invalid-feedback{
    background-color: white;
    border-radius: 2px;
  }
  .form-group label {
    font-size: 15px;
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .form-group input{
    font-size : 15px !important;
  }
  @media (max-width: 479px){
    .pasenger_form fieldset.form-group{
      display: flex;
      margin: 0px;
      width: 50%;
      float: left;
    }
  }

</style>

<style scoped>
.colum-title {
  align-self: center;
  display: grid;
  line-height: 14px;
}
.form-control.is-invalid, .invalid {
  border-color: #dc3545;
  padding-right: 0.75rem !important;
  background-image: none;
}
.form-control.is-valid, .custom-select.is-valid, .form-control.valid {
  border-color: #28a745;
  background-image: none;
  padding-right: 0.75rem !important;
}
.pasenger_form {
  box-sizing: border-box;
  margin-bottom: 15px;
  z-index: 91;
}

.passenger_body .pasenger_form h4 {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 0;
  padding: 0;
  text-transform: capitalize;
  letter-spacing: 1px;
  width: 100%;
  max-width: 80px;
  line-height: 26px;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .passenger_body .pasenger_form h4 {
    max-width: 132px;
  }
}

.pasenger_form .form-group {
  position: relative;
  height: 76px;
  min-width: 100px;
  margin-right: 20px;
  width: 100%;
}
/*
.pasenger_form fieldset.form-group{
  display: -webkit-inline-box;
} */

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pasenger_form .form-group .form-icon {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 15px;
  font-size: 20px;
  color: #606060;
}

.pasenger_form fieldset {
  direction: ltr;
  margin-top : -3px;
}

.form-group .icon-form{
    content: '';
    position: absolute;
    bottom: 12px;
    right: 4px;
    color: #28a745;
}

@media (max-width: 479px) {
  .pasenger_form {
    padding: 5px 5px;
    border: 1px solid #e5e5e5;
  }

  .passenger_body .pasenger_form h4 {
    color: rgb(33, 37, 41);
    line-height: 1.2;
  }

  .pasenger_form .form-group {
    color: rgb(33, 37, 41) !important;
  }

  .pasenger_form .form-group label {
    margin-top: 12px;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 50% !important;
  }
}

@media (max-width: 768px) {
  .pasenger_form form {
    display: inline-block !important;
  }
  .pasenger_form .form-group {
    width: 50%;
    display: inline-block;
    margin: auto;
    padding: 3px 10px;
    height: fit-content;
  }
}

@media (min-width: 750px) and (max-width: 1200px) {
  .passenger_box_two .pasenger_form form {
    display: inline-block !important;
  }

  .passenger_box_two .pasenger_form .form-group {
    width: 33%;
    display: inline-block !important;
    margin: auto;
    padding: 10px;
  }
}
/*
    @media (min-width: 1200px) {
        .form-group label, .form-group label.d-block{
            font-size: 15px;
        }
    } */
</style>
